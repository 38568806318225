<template>
  <div class="conditions">
    <div class="container my-4">
      <h1 class="h1 my-4" >Conditions de location</h1>
    </div>
    <div class="container shadow rounded py-3 text-justify">
      <ContenuConditions />
    </div>
    <div class="container center my-3">
      <router-link to="/gites" class="btn btn-lg btn-info m-3">Voir les gites</router-link>
    </div>
  </div>
</template>

<script>
import ContenuConditions from '@/components/ContenuConditions.vue'

export default {
  name: 'Conditions',
  components: {
    ContenuConditions
  }
}
</script>
