<template>
    <div>
      <h2 class="h4 my-4">Réglement général</h2>
      <hr>
      <p class="lead">
        Nos gîtes peuvent être loués au week-end, à la semaine, au mois ou plus.<br>
        L'eau et le chauffage sont compris dans les tarifs. Pour l'électricité, un relevé du compteur sera effectué à votre arrivée et à votre départ.<br>
        Le locataire est prié d’emmener le linge de lit, de toilette et les essuies de cuisine.<br>
        Le nettoyage est à charge du locataire. Le cas échéant, un supplément sera demandé.<br>
        La présence d’animaux n’est pas admise.
      </p>
      <h2 class="h4 my-4">Séjour</h2>
      <hr>
      <p class="lead">
        Concernant le séjour week-end : les arrivées ont lieu le vendredi après-midi à partir de 16h00, les départs le lundi matin au plus tard à 11h00.<br/>
        Concernant le séjour semaine : les arrivées ont lieu le lundi après-midi à partir de 16h00, les départs le vendredi matin au plus tard à 11h00.
      </p>
      <h2 class="h4 my-4">Paiement</h2>
      <hr>
      <p class="lead">
        Un acompte de 30% est demandé au moment de la réservation au compte suivant : <br>
        <br>
        IBAN : BE89 132 5180717 85 <br>
        BIC : BNAGBEBB <br>
        <br>
        Le solde est à payer au plus tard 30 jours avant le début du séjour ou, en cas de réservation tardive acceptée, sur place le jour de l’arrivée.<br>
        Une caution sera versée au moment de l’état des lieux établi le jour de l’entrée.
      </p>
    </div>
</template>

<script>
export default {
  name: 'ContenuConditions'
}
</script>
